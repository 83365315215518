import * as toastr from 'toastr';
export class Utils {

	constructor() {
		this.listeningSelector();
		this.customSelect();
		this.initToastr();
	}
	//Função para abrir janelas em nova aba ou em popup
	public static handleNovaJanela(e) {
		e.preventDefault();
		let url = e.target.getAttribute("href");
		let alturaJanela = e.target.getAttribute("data-altura");
		let larguraJanela = e.target.getAttribute("data-largura");

		if (larguraJanela || alturaJanela)
			window.open(url, "links", "width=" + larguraJanela + ", height=" + alturaJanela + "");
		else {
			larguraJanela = screen.availWidth;
			alturaJanela = screen.availHeight;
			window.open(url, "links", "width=" + larguraJanela + ", height=" + alturaJanela + "");
		}
	}

	//Função para recuperar mensagens no formato da API Ex.: [ 0 { chave: "chaveMensagem" mensagem: "Texto da mensagem" pagina: "Web" }]
	public static getMessage(chave: string, dados: any): string {

		let data = dados;
		let msg = "";
		Object.keys(data).filter(key => {
			if (data[key].chave === chave) {
				return msg = data[key].mensagem;
			}
		});
		return msg;
	}

	private listeningSelector(): void {

		var events = {}, selectors = {}, animationCount = 0, styles = document.createElement('style'),
		keyframes = document.head.appendChild(document.head.appendChild(styles).cloneNode()),
		prefix = window.CSSKeyframesRule ? { css: '', event: 'animationstart' } : {
			css: '-webkit-',
			event: 'webkitAnimationStart'
		},
		trimJoin = function(z){ return z.map(Function.prototype.call, String.prototype.trim).join(' ') },
		startEvent = function(e){
			e.selector = (events[e.animationName] || {}).selector;
			((this.selectorListeners || {})[e.animationName] || []).forEach(function (fn) { fn.call(this, e) }, this);
		}

		HTMLDocument.prototype.addSelectorListener = HTMLElement.prototype.addSelectorListener = function(values, fn){
		var rules = typeof values === 'string' ? [values] : values,
			id = rules.length > 1 ? trimJoin(values) : values,
			key = selectors[id],
					listeners = this.selectorListeners = this.selectorListeners || { count: 0 };

			if (key) {
				events[key].count++;
			} else {
				key = selectors[id] = 'selector-listener-' + animationCount++;
				var style = rules.pop() + '::before { content: ""; ' + prefix.css + 'animation: ' + key + ' 0.01s; }';
				(styles.sheet as CSSStyleSheet).insertRule(rules.reduceRight(function(current, next) {
					return next + '{' + current + '}'
				}, style), 0);
				events[key] = {
					count: 1,
					selector: id,
					keyframe: keyframes.appendChild(document.createTextNode('@' + prefix.css + 'keyframes ' + key + ' { to { outline-color: rgba(0,0,0,0) } }')),
					rule: (styles.sheet as CSSStyleSheet).cssRules[0]
				};
			}

			listeners.count ? listeners.count++ : this.addEventListener(prefix.event, startEvent, true);

			(listeners[key] = listeners[key] || []).push(fn);
		};

		HTMLDocument.prototype.removeSelectorListener = HTMLElement.prototype.removeSelectorListener = function(values, fn){
		var id = typeof values === 'string' ? values : trimJoin(values),
			listeners = this.selectorListeners || {},
					key = selectors[id],
					listener = listeners[key] || [],
					index = listener.indexOf(fn);

			if (index > -1){
				var event = events[selectors[id]];
				if (!--event.count){
					(styles.sheet as CSSStyleSheet).deleteRule((styles.sheet as any).cssRules.item(event.rule));
					keyframes.removeChild(event.keyframe);
					delete events[key];
					delete selectors[id];
				}

				listeners.count--;
				listener.splice(index, 1);
				if (!listeners.count) this.removeEventListener(prefix.event, startEvent);
			}
		};
	}

	public static getCdnUrlScript(): string {
        let scriptSrc: string;
		const scripts = document.getElementsByTagName('script');
		
        for (let i = 0; i < scripts.length; ++i) {
            if (scripts[i].src.indexOf('abFramework.main') !== -1) {
                const url = new URL(scripts[i].src);
                scriptSrc = url.origin;
                break;
            }
        }

        return scriptSrc ? scriptSrc : '//cdn.abaris.com.br';
    }

	public customSelect(): void {
		const self = this;
		let x, i, j, selElmnt, a, b, c;
        /*look for any elements with the class "input-content--select":*/
        x = document.getElementsByClassName("input-content--select");
        for (i = 0; i < x.length; i++) {
            selElmnt = x[i].getElementsByTagName("select")[0] as HTMLSelectElement;
            /*for each element, create a new DIV that will act as the selected item:*/
            a = document.createElement("DIV") as HTMLDivElement;
            a.setAttribute("class", "input input--select select-selected");
            a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
            x[i].appendChild(a);
            /*for each element, create a new DIV that will contain the option list:*/
            b = document.createElement("DIV") as HTMLDivElement;
            b.setAttribute("class", "select-items select-hide");
            for (j = 1; j < selElmnt.length; j++) {
                /*for each option in the original select element,
                create a new DIV that will act as an option item:*/
                c = document.createElement("DIV") as HTMLDivElement;
                c.innerHTML = selElmnt.options[j].innerHTML;
                c.addEventListener("click", function (e) {
                    /*when an item is clicked, update the original select box,
                    and the selected item:*/
                    var y, i, k, s, h;
                    s = c.parentNode.parentNode.getElementsByTagName("select")[0] as HTMLSelectElement;
                    h = c.parentNode.previousSibling;
                    for (i = 0; i < s.length; i++) {
                        if (s.options[i].innerHTML == c.innerHTML) {
                            s.selectedIndex = i;
                            h.innerHTML = c.innerHTML;
                            y = c.parentNode.getElementsByClassName("same-as-selected");
                            for (k = 0; k < y.length; k++) {
                                y[k].removeAttribute("class");
                            }
                            c.setAttribute("class", "same-as-selected");
                            break;
                        }
                    }
                    h.click();
                });
                b.appendChild(c);
            }
            x[i].appendChild(b);
			a.addEventListener("click", function (e) {
                /*when the select box is clicked, close any other select boxes,
                and open/close the current select box:*/
                e.stopPropagation();
                self.closeAllSelect(self);
                a.nextSibling.classList.toggle("select-hide");
                a.classList.toggle("select-arrow-active");
            });
        }

        /*if the user clicks anywhere outside the select box,
        then close all select boxes:*/
        document.addEventListener("click", this.closeAllSelect);
	}

	private closeAllSelect(elmnt) : void {
		/*a function that will close all select boxes in the document,
		except the current select box:*/
		var x, y, i, arrNo = [];
		x = document.getElementsByClassName("select-items");
		y = document.getElementsByClassName("select-selected");
		for (i = 0; i < y.length; i++) {
			if (elmnt == y[i]) {
				arrNo.push(i)
			} else {
				y[i].classList.remove("select-arrow-active");
			}
		}
		for (i = 0; i < x.length; i++) {
			if (arrNo.indexOf(i)) {
				x[i].classList.add("select-hide");
			}
		}
	}

	public static wordWrap(str: string, maxWidth: number): string {
		let newLineStr: string = "\n";
		const done: boolean = false;
		let res: string = '';
		let i: number;
		while (str.length > maxWidth) {                 
			let found = false;
			// Inserts new line at first whitespace of the line
			for (i = maxWidth - 1; i >= 0; i--) {
				if (this.testWhiteSpace(str.charAt(i))) {
					res = res + [str.slice(0, i), newLineStr].join('');
					str = str.slice(i + 1);
					found = true;
					break;
				}
			}
			// Inserts new line at maxWidth position, the word is too long to wrap
			if (!found) {
				res += [str.slice(0, maxWidth), newLineStr].join('');
				str = str.slice(maxWidth);
			}
	
		}
	
		return res + str;
	}
	
	public static testWhiteSpace(x): boolean {
		let white = new RegExp(/^\s$/);
		return white.test(x.charAt(0));
	};

	public static truncateString(str: string, num: number): string {
		let newStr: string = str;
		
		if (num < str.length){
		  newStr = str.slice(0, num)+ "...";
		} 
		else if (num < 3) {
		  newStr = str.slice(0, 3)+ "...";
		}
		
		return newStr;
	}

	// Gerar números aleatórios em um intervalo definido
	public static getRandomInt(min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min;
	};

	private initToastr(): void {
        toastr.options.closeButton = true;
        toastr.options.debug = false;
        toastr.options.newestOnTop = false;
        toastr.options.progressBar = true;
        toastr.options.positionClass = "toast-top-right";
        toastr.options.preventDuplicates = true;
        toastr.options.onclick = null;
        toastr.options.showDuration = 300;
        toastr.options.hideDuration = 1000;
        toastr.options.timeOut = 5000;
        toastr.options.extendedTimeOut = 1000;
        toastr.options.showEasing = "swing";
        toastr.options.hideEasing = "linear";
    }

	public getToastrWarning(msg: string, title?: string, overrides?: any): any {
        return toastr.warning(msg, title, overrides);
	}
	
    public getToastrSuccess(msg: string, title?: string, overrides?: any): any {
        return toastr.success(msg, title, overrides);
	}
	
    public getToastrError(msg: string, title?: string, overrides?: any): any {
        return toastr.error(msg, title, overrides);
    }

}
